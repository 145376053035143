//React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

//Actions
import { listYears } from "../../actions/yearActions";
import { listWeeks } from "../../actions/weekActions";
import { listDays } from "../../actions/dayActions";
import { logout } from "../../actions/userActions";

//Internal Components
import DropdownLoading from "../../components/Loading/DropdownLoading";
import {
  ProfileIcon,
  YearIcon,
  WeekIcon,
  DiaryIcon,
  InsightsIcon,
  LogoutIcon,
  ValuesIcon,
} from "../../components/Icons/Icon";
import dateFormatter from "../../utils/DateFormatter";

//External Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faMagnifyingGlass,
  faBug,
} from "@fortawesome/free-solid-svg-icons";

//Images
import wavy_top from "../../images/Assets/PageBreakers/1920x100_Bottom.svg";

//Styling
import "./header.css";

const CentralHeader = (props) => {
  const [homepage, setHomepage] = useState(
    window.location.href === "/" ? true : false
  );
  const [darkMode, setDarkMode] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [userStatus, setUserStatus] = useState();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const yearList = useSelector((state) => state.yearList);
  const { years } = yearList;

  const weekList = useSelector((state) => state.weekList);
  const { weeks } = weekList;

  const dayList = useSelector((state) => state.dayList);
  const { loading, error, days } = dayList;

  useEffect(() => {
    dispatch(listYears());
  }, [dispatch, userInfo]);

  useEffect(() => {
    dispatch(listWeeks());
  }, [dispatch, userInfo]);

  useEffect(() => {
    dispatch(listDays());
  }, [dispatch, userInfo]);

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
  };

  const [pageLoading, setPageLoading] = useState(true);

  const [yearMap, setYearMap] = useState();
  const [weeksMap, setWeeksMap] = useState();
  const [daysMap, setDaysMap] = useState();

  const yearMapChange = () => {
    const id =
      years &&
      years
        .filter((year, i, years) => years.indexOf(year) === years.length - 1)
        .map((year) => year._id);

    if (years && years.length >= 1) {
      setYearMap((yearMap) => `/year/${id}`);
    } else {
      setYearMap((yearMap) => `/year/create`);
    }
  };

  const weekMapChange = () => {
    const id =
      weeks &&
      weeks
        .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
        .map((week) => week._id);
    if (weeks && weeks.length > 0) {
      setWeeksMap((weeksMap) => `/week/${id}`);
    } else {
      setWeeksMap((weeksMap) => `/week/create`);
    }
  };

  const dayMapChange = async () => {
    let endScoreTrue = false;
    let id;

    if (Array.isArray(days) === true) {
      id =
        days &&
        (await days
          .filter((day, i, days) => days.indexOf(day) === days.length - 1)
          .map((day) => day._id));

      let testValue = await days
        .filter((day, i, days) => days.indexOf(day) === days.length - 1)
        .map((day) => day.endScore);

      if (testValue[0] === null || testValue[0] === undefined) {
        endScoreTrue = true;
      }
    }

    if (days && days.length > 0 && endScoreTrue) {
      setDaysMap((daysMap) => `/today/${id[0]}`);
    } else {
      setDaysMap((daysMap) => `/today`);
    }
  };

  const loadingTimeout = () => {
    var randNum = Math.random(1) * 3000 + Math.random(1) * 3000;
    setTimeout(() => {
      if (days) {
        setPageLoading(false);
      }
    }, randNum);
  };

  const currentWindow = window.location.href;

  useEffect(() => {
    loadingTimeout();
    yearMapChange();
    weekMapChange();
    dayMapChange();
    if (
      window.location.href.includes("/insight") ||
      window.location.href.includes("/values") ||
      window.location.href.includes("/debrief")
    ) {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  });

  const vw = Math.max(document.documentElement.clientWidth);
  const vw2 = Math.max(window.innerWidth);

  const [menuState, setMenuState] = useState("menu");
  const [navMenu, setNavMenu] = useState("navMenu");
  const [navDock, setNavDock] = useState("navDock");

  const openMenu = () => {
    if (menuState === "menu") {
      setMenuState((menuState) => "menu active");
      setNavMenu((navMenu) => "navMenu active");
      setNavDock((navDock) => "navDock");
    } else {
      setMenuState((menuState) => "menu");
      setNavMenu((navMenu) => "navMenu");
      setNavDock((navDock) => "navDock");
    }
  };

  const openDock = () => {
    if (menuState === "menu") {
      setMenuState((menuState) => "menu active");
      setNavDock((navDock) => "navDock active");
      setNavMenu((navMenu) => "navMenu");
    } else {
      setMenuState((menuState) => "menu");
      setNavDock((navDock) => "navDock");
      setNavMenu((navMenu) => "navMenu");
    }
  };

  const [insightTextState, setInsightTextState] = useState("Insights");
  const [headerSpan, setHeaderSpan] = useState();

  // const [searchDateData, setSearchDateData] = useState([]);

  const [trialDays, setTrialDays] = useState();

  useEffect(() => {
    if (
      !userInfo ||
      !userInfo.subscription ||
      !userInfo.subscription.userStart
    ) {
      console.log("No User Data");
    } else {
      const trialStartDate = userInfo.subscription.userStart;
      const currentTier = userInfo.subscription.plan;

      if (currentTier === "trial") {
        const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
        const trialStartDateMs = new Date(trialStartDate).getTime();
        const trialStartDateStart = new Date(trialStartDate);
        let thirtyDaysAhead = new Date(trialStartDateStart);
        thirtyDaysAhead.setDate(trialStartDateStart.getDate() + 30);
        const differenceInMilliseconds = Math.abs(thirtyDaysAhead - Date.now());
        const differenceInDays = Math.ceil(
          differenceInMilliseconds / (1000 * 60 * 60 * 24)
        );

        const currentDateMs = new Date().getTime();

        setTrialDays(differenceInDays);
      } else if (currentTier !== "trial") {
        setTrialDays(0);
      }
    }
  }, []);

  const userFunction = () => {
    if (
      !userInfo ||
      !userInfo.subscription ||
      !userInfo.subscription.userStart
    ) {
      console.log("No User Data");
    } else if (
      userInfo.subscription.plan === "trial" ||
      userInfo.subscription.plan === "classic_annual" ||
      userInfo.subscription.plan === "classic_monthly" ||
      userInfo.subscription.plan === "core_annual" ||
      userInfo.subscription.plan === "core_monthly"
    ) {
      setUserStatus("no_coach");
    } else {
      setUserStatus("coach");
    }
  };

  useEffect(() => {
    userFunction();
  }, [userInfo]);

  return (
    <>
      {vw >= 1025 ? (
        <>
          <nav className={darkMode ? `nav central dark` : `nav central`}>
            <div className="box left">
              {Array.isArray(days) === true ? (
                <a href={daysMap}>
                  {darkMode ? (
                    <div
                      className={
                        darkMode
                          ? `navElement dark stack`
                          : props.tutorialStep === 4
                            ? "navElement opac50"
                            : `navElement`
                      }
                    >
                      <img
                        src="https://cdn.sanity.io/images/ou6d2gec/production/cd1a77ad4d64832bf1c08fedeb3c3701058809aa-493x336.svg"
                        alt="no wasted days"
                        width="42px"
                        height="28px"
                      ></img>
                    </div>
                  ) : (
                    <ValuesIcon />
                  )}
                </a>
              ) : (
                <a href={"/today"} target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://cdn.sanity.io/images/ou6d2gec/production/87eec4d22fdeb99bdae99a9822c0eabb3ab18f36-1080x93.svg"
                    alt="no wasted days"
                    width="100%"
                  ></img>
                </a>
              )}
            </div>

            <div className="box right">
              {pageLoading ? (
                <a>
                  <DropdownLoading />
                </a>
              ) : currentWindow.includes("/today") ? (
                <>
                  <div className="navWrapper">
                    <a>
                      <div
                        className={
                          darkMode
                            ? `navElement dark stack`
                            : props.tutorialStep === 4
                              ? "navElement opac50"
                              : `navElement`
                        }
                      >
                        <div className="header node white">
                          <FontAwesomeIcon
                            style={
                              darkMode
                                ? { color: "#004a22" }
                                : { color: "#e2dfcf" }
                            }
                            className="icon green"
                            icon={faMagnifyingGlass}
                          />
                        </div>

                        {/* <input
                          className={
                            darkMode ? `searchInput dark` : `searchInput`
                          }
                          type="search"
                          placeholder="Search your journal"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        /> */}
                      </div>
                    </a>
                    {/* {searchQuery && (
                      <ul
                        className={darkMode ? `searchList dark` : `searchList`}
                      >
                        {filteredDates.map((d) => (
                          <li
                            className={
                              darkMode ? `searchItem dark` : `searchItem`
                            }
                            key={d.id}
                          >
                            <a
                              id={darkMode ? `searchItemDark` : `searchItem`}
                              href={`/today/${d.id}`}
                            >
                              {d.date}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )} */}
                  </div>
                </>
              ) : (
                <></>
              )}

              {pageLoading ? (
                <a>
                  <DropdownLoading />
                </a>
              ) : !currentWindow.endsWith("/my-account/menu") ? (
                <a href={yearMap}>
                  <div
                    className={
                      darkMode
                        ? `navElement dark stack`
                        : props.tutorialStep === 4 || props.tutorialStep === 6
                          ? "navElement opac50"
                          : `navElement`
                    }
                  >
                    <div className="header node white">
                      <YearIcon />
                    </div>
                    <h6>Map</h6>
                  </div>
                </a>
              ) : (
                <></>
              )}

              {pageLoading ? (
                <a>
                  <DropdownLoading />
                </a>
              ) : !currentWindow.endsWith("/my-account/menu") ? (
                <a href={weeksMap}>
                  <div
                    className={
                      darkMode
                        ? `navElement dark stack`
                        : props.tutorialStep === 4 || props.tutorialStep === 5
                          ? "navElement opac50"
                          : `navElement`
                    }
                  >
                    <div className="header node white">
                      <WeekIcon />
                    </div>
                    <h6>Planner</h6>
                  </div>
                </a>
              ) : (
                <></>
              )}

              {pageLoading ? (
                <a>
                  <DropdownLoading />
                </a>
              ) : Array.isArray(days) === true ? (
                <a href={daysMap}>
                  <div
                    className={
                      darkMode
                        ? `navElement dark stack`
                        : props.tutorialStep >= 4 && props.tutorialStep <= 6
                          ? "navElement opac50"
                          : `navElement`
                    }
                  >
                    <div className="header node white">
                      <DiaryIcon className="icon green" />
                    </div>
                    <h6>Journal</h6>
                  </div>
                </a>
              ) : (
                <a href={"/today"}>
                  <div
                    className={
                      darkMode
                        ? `navElement dark stack`
                        : props.tutorialStep >= 4 && props.tutorialStep <= 6
                          ? "navElement opac50"
                          : `navElement`
                    }
                  >
                    <div className="header node white">
                      <DiaryIcon />
                    </div>
                    <h6>Journal</h6>
                  </div>
                </a>
              )}

              {pageLoading ? (
                <a>
                  <DropdownLoading />
                </a>
              ) : days && days.length >= 7 ? (
                <a href={"/insight"}>
                  <div
                    className={
                      darkMode
                        ? `navElement dark stack`
                        : props.tutorialStep === 5 || props.tutorialStep === 6
                          ? "navElement opac50"
                          : `navElement`
                    }
                  >
                    <div className="header node white">
                      <InsightsIcon className="icon green" />
                    </div>
                    <h6>{insightTextState}</h6>
                  </div>
                </a>
              ) : (
                // Add tooltip to indicate days remaining
                <a
                  href="/"
                  onMouseEnter={() => {
                    setInsightTextState(
                      `Complete ${7 - days.length} more days.`
                    );
                    setHeaderSpan("smallSpan");
                  }}
                  onMouseLeave={() => {
                    setInsightTextState("Insights");
                    setHeaderSpan();
                  }}
                >
                  <div
                    className={
                      darkMode
                        ? `navElement dark stack`
                        : props.tutorialStep === 5 || props.tutorialStep === 6
                          ? "navElement opac50"
                          : `navElement`
                    }
                  >
                    <div className="header node white">
                      <InsightsIcon />
                    </div>
                    <h6 className={headerSpan}>{insightTextState}</h6>
                  </div>
                </a>
              )}

              {pageLoading ? (
                <a>
                  <DropdownLoading />
                </a>
              ) : vw > 1100 ? (
                <a href={"/my-account/menu"}>
                  <div
                    className={
                      darkMode
                        ? `navElement dark stack`
                        : props.tutorialStep >= 4 && props.tutorialStep <= 6
                          ? "navElement opac50"
                          : `navElement`
                    }
                  >
                    <div className="header node white">
                      <ProfileIcon />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h6>My Account</h6>
                      {trialDays !== 0 &&
                        trialDays <= 30 &&
                        userStatus === "no_coach" && (
                          <span
                            style={{ fontSize: "0.6em", fontWeight: "200" }}
                          >
                            Ad Free Days Left: {trialDays}
                          </span>
                        )}
                    </div>
                  </div>
                </a>
              ) : (
                <></>
              )}

              {pageLoading ? (
                <a>
                  <DropdownLoading />
                </a>
              ) : vw > 1100 ? (
                <a href={"/contact/bug_found"}>
                  <div
                    className={
                      darkMode
                        ? `navElement dark stack`
                        : props.tutorialStep >= 4 && props.tutorialStep <= 6
                          ? "navElement opac50"
                          : `navElement`
                    }
                  >
                    <div className="header node white">
                      <FontAwesomeIcon icon={faBug} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h6>Report a Bug</h6>
                    </div>
                  </div>
                </a>
              ) : (
                <></>
              )}

              {pageLoading ? (
                <a>
                  <DropdownLoading />
                </a>
              ) : currentWindow.endsWith("/my-account/menu") ? (
                <a onClick={logoutHandler}>
                  <div className={darkMode ? `navElement dark` : `navElement`}>
                    <div className="header node white">
                      {/* <FontAwesomeIcon className="icon green" icon={faRightFromBracket} /> */}
                      <LogoutIcon />
                    </div>
                    <h6>Logout</h6>
                  </div>
                </a>
              ) : (
                <></>
              )}
            </div>
          </nav>
          {darkMode && (
            <img
              className="wave--top--header"
              src={wavy_top}
              alt="wavy asset top"
              width="100%"
            />
          )}
        </>
      ) : (
        <>
          <nav className="nav mobile">
            <a href={"/"}>
              <div className="navElement_logo" style={{ marginLeft: "6px" }}>
                <ValuesIcon />
              </div>
            </a>

            {pageLoading ? (
              <a id="nava">
                <DropdownLoading />
              </a>
            ) : !currentWindow.endsWith("/my-account/menu") ? (
              <>
                {/* <input
                  className="searchInput"
                  type="search"
                  placeholder="Search your journal"
                  value={searchQuery}
                  onChange={handleSearchChange}
                /> */}

                {/* {searchQuery && (
                  <ul className="searchList">
                    {filteredDates.map((d) => (
                      <li className="searchItem" key={d.id}>
                        <a id="searchItem" href={`/today/${d.id}`}>
                          {d.date}
                        </a>
                      </li>
                    ))}
                  </ul>
                )} */}
              </>
            ) : (
              <a id="nava" onClick={logoutHandler}>
                Log out
                <div id="underline"></div>
              </a>
            )}
            <div onClick={openMenu} style={{ marginRight: "18px" }}>
              <FontAwesomeIcon
                id="burgerMenu"
                className="burger"
                icon={faBars}
              />
            </div>
          </nav>

          <div className={menuState}>
            <ul className={navMenu}>
              <li className="listItem">
                {pageLoading ? (
                  <a id="nava">
                    <DropdownLoading />
                  </a>
                ) : (
                  <a id="nava" href={yearMap}>
                    <strong>Map</strong>
                  </a>
                )}
              </li>
              <li className="listItem">
                {pageLoading ? (
                  <a id="nava">
                    <DropdownLoading />
                  </a>
                ) : (
                  <a id="nava" href={weeksMap}>
                    <strong>Planner</strong>
                  </a>
                )}
              </li>
              <li className="listItem">
                {pageLoading ? (
                  <a id="nava">
                    <DropdownLoading />
                  </a>
                ) : Array.isArray(days) === true ? (
                  <a id="nava" href={daysMap}>
                    <strong>Journal</strong>
                  </a>
                ) : (
                  <a id="nava" href={"/today"}>
                    <strong>Journal</strong>
                  </a>
                )}
              </li>

              <li className="listItem">
                {pageLoading ? (
                  <a id="nava">
                    <DropdownLoading />
                  </a>
                ) : currentWindow.endsWith("/my-account/menu") ? (
                  <a id="nava" onClick={logoutHandler}>
                    <strong>Log out</strong>{" "}
                  </a>
                ) : days && days.length > 7 ? (
                  <a id="nava" href={"/insight"}>
                    <strong>Insight</strong>
                  </a>
                ) : (
                  <a id="nava" href={daysMap}>
                    <strong>
                      <s>Insight</s>
                    </strong>
                  </a>
                )}
              </li>
              <li className="listItem">
                {pageLoading ? (
                  <a id="nava">
                    <DropdownLoading />
                  </a>
                ) : (
                  <a id="nava" href={"/my-account/menu"}>
                    <strong>My Account</strong>
                  </a>
                )}
              </li>
              <li className="listItem">
                {pageLoading ? (
                  <a id="nava">
                    <DropdownLoading />
                  </a>
                ) : (
                  <a id="nava" href={"/contact/bug_found"}>
                    <strong>Report a bug</strong>
                  </a>
                )}
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default CentralHeader;
