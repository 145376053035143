import {
  YEARS_CREATE_FAIL,
  YEARS_CREATE_REQUEST,
  YEARS_CREATE_SUCCESS,
  YEARS_UPDATE_FAIL,
  YEARS_UPDATE_REQUEST,
  YEARS_UPDATE_SUCCESS,
  YEARS_LIST_FAIL,
  YEARS_LIST_REQUEST,
  YEARS_LIST_SUCCESS,
} from "../constants/yearsConstants";
import axios from "axios";

export const listYears = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: YEARS_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `https://nwd-backend.herokuapp.com/api/years/`,
      config
    );

    dispatch({
      type: YEARS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: YEARS_LIST_FAIL,
      payload: message,
    });
  }
};

export const createYearAction = (me_year) => async (dispatch, getState) => {
  try {
    dispatch({
      type: YEARS_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `https://nwd-backend.herokuapp.com/api/years/year/create`,
      { me_year },
      config
    );

    dispatch({
      type: YEARS_CREATE_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: YEARS_CREATE_FAIL,
      payload: message,
    });
  }
};

export const updateYearAction = (id, me_year) => async (dispatch, getState) => {
  try {
    dispatch({
      type: YEARS_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `https://nwd-backend.herokuapp.com/api/years/year/${id}`,
      {
        me_year,
      },
      config
    );

    dispatch({
      type: YEARS_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: YEARS_UPDATE_FAIL,
      payload: message,
    });
  }
};
