//React
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLinesVertical } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

//Internal Components
import {
  CreateDayIconV2,
  ValuesIcon,
  VitIcon,
  VisionIcon,
  IkigaiIcon,
  QuoteIconV2,
  HideOverlayIcon,
  QuestionIcon,
  OneIcon,
  TwoIcon,
  ThreeIcon,
  FourIcon,
  FiveIcon,
  SixIcon,
  SevenIcon,
  EightIcon,
  NineIcon,
  TenIcon,
  SaveIcon,
  PreviousDay,
} from "../../components/Icons/Icon";

//CSS Style
import "./dock.css";

export default function FullDock(props) {
  // Hooks
  const navigate = useNavigate();

  // State
  const [isVisible, setIsVisible] = useState(true);
  const [colorState, setColorState] = useState({
    one: "cls-1-invert",
    two: "cls-2-invert",
  });
  const [viewWidth, setViewWidth] = useState(
    Math.max(document.documentElement.clientWidth)
  );

  useEffect(() => {
    const handleScroll = () => {
      if (viewWidth > 1024) {
        setIsVisible(window.scrollY <= 0);
      } else {
        setIsVisible(window.scrollY <= 1000);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [viewWidth]);

  const handleHover = (e) => {
    e.target.style.bottom = "24px";
  };

  return (
    <section className="dock--section">
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: isVisible ? 1 : 0 }}
        transition={{ duration: 0.3 }}
        className={"dock--container"}
      >
        <div className={`jordandock node`}>
          <CreateDayIconV2 />
        </div>
        <div
          // Ensure this node is disabled if no previous day exists
          className={`jordandock node`}
          onClick={props.previousDay}
        >
          <PreviousDay />
        </div>
        <div
          className={`jordandock node`}
          data-tooltip={"Quickly learn more about your journal"}
        >
          <QuestionIcon />
        </div>

        <div className="dock separator">
          <FontAwesomeIcon
            id="separatorIcon"
            className="separator"
            icon={faGripLinesVertical}
          />
        </div>

        <div
          className={`jordandock node`}
          // Replace with passing year, initialising it as state and then calling the name key

          data-tooltip={
            "Add your name and date of birth to your year map to access it in the dock."
          }
        >
          <VitIcon />
        </div>

        <div
          style={{ height: "40px", width: "40px" }}
          className={`jordandock node`}
          data-tooltip={
            "Add your values in your year map to access them in the dock."
          }
        >
          <ValuesIcon />
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            "Add your vision to your year map to access it through the dock."
          }
        >
          <VisionIcon />
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            "Add your ikigai to your year map to access it in the dock"
          }
        >
          <IkigaiIcon />
        </div>
        <div
          className={`jordandock node`}
          data-tooltip={
            "Add a quote to your year map to access it in the dock."
          }
        >
          <QuoteIconV2 />
        </div>

        <div className={`jordandockwide node`} data-tooltip="Hide Overlay">
          <HideOverlayIcon />
        </div>

        <div className="dock separator">
          <FontAwesomeIcon
            id="separatorIcon"
            className="separator"
            icon={faGripLinesVertical}
          />
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            "Add an objective into your week planner to access it here."
          }
        >
          <OneIcon opacity={props.opacityOne} />
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            "Add an objective into your week planner to access it here."
          }
        >
          <TwoIcon opacity={props.opacityTwo} />
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            "Add an objective into your week planner to access it here."
          }
        >
          <ThreeIcon opacity={props.opacityThree} />
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            "Add an objective into your week planner to access it here."
          }
        >
          <FourIcon opacity={props.opacityFour} />
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            "Add an objective into your week planner to access it here."
          }
        >
          <FiveIcon opacity={props.opacityFive} />
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            "Add an objective into your week planner to access it here."
          }
        >
          <SixIcon opacity={props.opacitySix} />
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            "Add an objective into your week planner to access it here."
          }
        >
          <SevenIcon opacity={props.opacitySeven} />
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            "Add an objective into your week planner to access it here."
          }
        >
          <EightIcon opacity={props.opacityEight} />
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            "Add an objective into your week planner to access it here."
          }
        >
          <NineIcon opacity={props.opacityNine} />
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            "Add an objective into your week planner to access it here."
          }
        >
          <TenIcon opacity={props.opacityTen} />
        </div>

        <div className="dock separator">
          <FontAwesomeIcon
            id="separatorIcon"
            className="separator"
            icon={faGripLinesVertical}
          />
        </div>

        <motion.div
          className={`jordandock node_save`}
          data-tooltip={"Click to Save"}
        >
          <SaveIcon
            saved={false}
            indicate={""}
            colorStateOne={colorState.one}
            colorStateTwo={colorState.two}
          />
        </motion.div>
      </motion.div>
    </section>
  );
}
