// React
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faCropSimple,
} from "@fortawesome/free-solid-svg-icons";

// Internal Components
import {
  rtbData,
  testimonyData,
  faqQuestionData,
  faqAnswerData,
} from "./LPDATA";
import CentralHeaderLP from "../../layouts/Header/CentralHeaderLP";
import TestFooter from "../../layouts/Footer/TestFooter";
import FAQ from "../../components/FAQ/FAQ";
import VideoComponent from "../../components/Video/Video";

// Assets
// Sanity Client
import { client } from "../../client";
import wavy_bottom from "../../images/Assets/PageBreakers/1920x100_Top.svg";
import wavy_top from "../../images/Assets/PageBreakers/1920x100_Bottom.svg";

// User Actions

// Styling
import "./landingpage.css";
import CentralHeader from "../../layouts/Header/CentralHeader";
import {
  VideoCarousel,
  ReviewCarousel,
} from "../../components/Carousel/Carousel";

function LandingScreen() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const vw = Math.max(document.documentElement.clientWidth);

  const [orientation, setOrientation] = useState(window.orientation);
  const [content, setContent] = useState();

  async function getPosts() {
    const posts = await client.fetch(`*[_type == 'post']{
      _id,
      title,
      mainImage {
        asset -> {
          _id,
          url
        },
      },
    }`);
    return posts;
  }

  useEffect(() => {
    const fetchData = async () => {
      let picsObj = [];

      try {
        const response = await getPosts();
        if (response) {
          for (let i = 0; i < response.length; i++) {
            if (response[i].title.startsWith("LP_")) {
              picsObj.push({
                id: response[i].mainImage.asset._id,
                title: response[i].title,
                url: response[i].mainImage.asset.url,
              });
            }
          }
        }
        setContent(picsObj);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // const [videos, setVideos] = useState([]);

  // useEffect(() => {
  //   client
  //     .fetch(
  //       '*[_type == "video"]{title, description, "videoUrl": videoFile.asset->url}'
  //     )
  //     .then((data) => setVideos(data))
  //     .catch(console.error);
  // }, []);

  const [videoIndex, setVideoIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setVideoIndex((prevIndex) => (prevIndex + 1) % content.length);

      setFadeIn(false);
      setTimeout(() => {
        setFadeIn(true);
      }, 1000);
    }, 8000);

    return () => clearInterval(interval);
  }, [content]);

  const changeOrient = (orient) => {
    setOrientation(orient);
  };

  useEffect(() => {
    let orientOne = window.orientation;
    let width = window.innerWidth;
    let height = window.innerHeight;
    let orientTwo = width / height;

    if (orientOne === undefined) {
      if (orientTwo > 1) {
        changeOrient(90);
      } else {
        changeOrient(0);
      }
    } else {
      changeOrient(orientOne);
    }
  }, [orientation]);

  const [carouselLinks, setCarouselLinks] = useState([
    {
      url: "https://cdn.sanity.io/files/ou6d2gec/production/1474fa0e18826a79e38d24bcbe770013a3bf98dd.mov",
      header: "It's a Coach",
      num: 0,
    },
    {
      url: "https://cdn.sanity.io/files/ou6d2gec/production/749899cbd61d871d3ee4e03e0505e20b16ac63f0.mp4",
      header: "It's a Journal",
      num: 1,
    },
    {
      url: "https://cdn.sanity.io/files/ou6d2gec/production/8b57e584dd369ad59c4b64cfe9bd3f307e9640a4.mov",
      header: "It's a Planner",
      num: 2,
    },
    {
      url: "https://cdn.sanity.io/files/ou6d2gec/production/ddebf72818ecd1b4ce26b4ef5a411faaa6c70b22.mov",
      header: "It's a Coach",
      num: 3,
    },
    {
      url: "https://cdn.sanity.io/files/ou6d2gec/production/749899cbd61d871d3ee4e03e0505e20b16ac63f0.mp4",
      header: "It's a Journal",
      num: 4,
    },
    {
      url: "https://cdn.sanity.io/files/ou6d2gec/production/a2845d0dc34ce6930b77ef61ee6987e258b18774.mov",
      header: "It's a Planner",
      num: -5,
    },
  ]);

  const [currentIndex, setCurrentIndex] = useState(1);
  const [carouselArray, setCarouselArray] = useState();
  const [carouselClicked, setCarouselClicked] = useState(false);

  const handleIndexChangeForward = () => {
    setCurrentIndex((prevState) => (prevState + 1) % carouselLinks.length);
    setCarouselClicked(true);
  };

  const handleIndexChangeBackward = () => {
    setCurrentIndex(
      (prevState) =>
        (prevState - 1 + carouselLinks.length) % carouselLinks.length
    );
    setCarouselClicked(true);
  };

  const filteredCarousel = () => {
    const lastIndex = carouselLinks.length - 1;

    const getFilteredIndex = (index) => {
      if (index === 0) {
        return [lastIndex, 0, 1];
      } else if (index === lastIndex) {
        return [lastIndex - 1, lastIndex, 0];
      } else {
        return [index - 1, index, index + 1];
      }
    };

    const filteredLinks = getFilteredIndex(currentIndex).map(
      (idx) => carouselLinks[idx]
    );
    setCarouselArray(filteredLinks);
  };

  useEffect(() => {
    filteredCarousel();
  }, [currentIndex]);

  return (
    <>
      {userInfo ? <CentralHeader /> : <CentralHeaderLP />}
      <main className="landingpage__main">
        {currentIndex === 1 && !carouselClicked ? (
          <h1 className="landingpage--header">What is NoWastedDays.io?</h1>
        ) : (
          <h1 className="landingpage--header">{carouselArray[1].header}</h1>
        )}
        <section className="header__section">
          <div className="header__row header__row--testimony">
            <img
              className="wave--bottom"
              src={wavy_bottom}
              alt="wavy asset bottom"
              width="100%"
              loading="lazy"
            />

            <VideoCarousel
              data={carouselArray}
              handleIndexChangeBackward={handleIndexChangeBackward}
              handleIndexChangeForward={handleIndexChangeForward}
            />

            <img
              className="wave--top"
              src={wavy_top}
              alt="wavy asset top"
              width="100%"
              loading="lazy"
            />
          </div>
        </section>
        {/* <ReviewCarousel imgtest={hero} /> */}

        <section className="story__section">
          <img
            className="wave--bottom"
            src={wavy_bottom}
            alt="wavy asset bottom"
            width="100%"
            loading="lazy"
          />
          <div className="main--message">
            <h1 style={{ color: "#e2dfcf" }}>
              NoWastedDays.io aims to make every day easier to prepare for, plan
              and process using our practical & powerful tools.
            </h1>
            <button className="landingpage__button">Try it out today!</button>
          </div>
          <FAQ />
          <img
            className="wave--top"
            src={wavy_top}
            alt="wavy asset top"
            width="100%"
            loading="lazy"
          />
        </section>

        <TestFooter />
      </main>
    </>
  );
}

export default LandingScreen;
