// React
import React, { useState } from "react";

// Internal Components
import PlanningTile from "./PlanningTile";
import ProcessingTile from "./ProcessingTile";
import PrimingTile from "./PrimingTile";
import { FullDockMobileOne, FullDockMobileTwo } from "../dock/FullDockMobile";
import FullDock from "../dock/FullDock";

function TileContainer({
  today,
  tooltipPosition,
  continuation,
  background,
  handleChange,
  ...props
}) {
  return (
    <form>
      <section {...props}>
        <div className="tile__container">
          <PrimingTile
            today={today}
            tooltipPosition={tooltipPosition}
            background={background}
            handleChange={handleChange}
          />
          {/* <FullDockMobileOne />*/}

          <PlanningTile
            today={today}
            tooltipPosition={tooltipPosition}
            continuation={continuation}
            background={background}
            handleChange={handleChange}
          />
          {/* <FullDockMobileTwo />*/}
          <ProcessingTile
            today={today}
            tooltipPosition={tooltipPosition}
            background={background}
            handleChange={handleChange}
          />
        </div>
        <FullDock />
      </section>
    </form>
  );
}

export default TileContainer;
