import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Column from "./Column";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../Dropable";
import { order } from "@xstyled/styled-components";

const Board = ({
  isCombineEnabled,
  ordered,
  onDragEnd,
  initial,
  overlay,
  useClone,
  containerHeight,
  withScrollableColumns,
  addSubTask,
  addStep,
  toggleSubTask,
  toggleStep,
  toggleOverlay,
  updateTitle,
  onDragEndSteps,
  deleteObjective,
  deleteStep,
  deleteSubtask,
  updateDescription,
  updateStep,
  updateNotes,
}) => {
  useEffect(() => {
    console.log(initial);
    console.log(ordered);
  });
  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable
          droppableId="board"
          type="COLUMN"
          direction="horizontal"
          ignoreContainerClipping={Boolean(containerHeight)}
          isCombineEnabled={isCombineEnabled}
        >
          {(provided) => (
            <div
              style={{
                backgroundColor: "#e2dfcf",
                minHeight: "100vh",
                /* like display:flex but will allow bleeding over the window width */
                minWidth: "100vw",
                display: "inline-flex",
                overflowY: "hidden",
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {ordered &&
                ordered.map((key, index) => (
                  <Column
                    key={key}
                    index={index}
                    title={key}
                    order={ordered}
                    objective={initial[key]}
                    overlay={overlay}
                    isScrollable={withScrollableColumns}
                    isCombineEnabled={isCombineEnabled}
                    useClone={useClone}
                    updateTitle={updateTitle}
                    addSubTask={addSubTask}
                    addStep={addStep}
                    toggleSubTask={toggleSubTask}
                    toggleStep={toggleStep}
                    toggleOverlay={toggleOverlay}
                    deleteObjective={deleteObjective}
                    deleteStep={deleteStep}
                    deleteSubtask={deleteSubtask}
                    updateDescription={updateDescription}
                    updateStep={updateStep}
                    updateNotes={updateNotes}
                    onDragEndSteps={onDragEndSteps}
                  />
                ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </>
  );
};

Board.defaultProps = {
  isCombineEnabled: false,
};

Board.propTypes = {
  isCombineEnabled: PropTypes.bool,
};

export default Board;
