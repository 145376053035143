// React
import React, { useState, useEffect } from "react";

// Internal Components
import AutoResizeTextArea from "../../components/AutoResizeTextarea/AutosizeTextarea";
import SliderTooltip from "../../components/Tooltips/Tooltips";

function PrimingTile({
  today,
  tooltipPosition,
  background,
  handleChange,
  cornerState,
}) {
  // State
  const [viewWidth, setViewWidth] = useState(
    Math.round(Math.max(document.documentElement.clientWidth))
  );

  return (
    <div className="tile left">
      <div className="dateRow--header">
        <label
          className="score label"
          style={{ opacity: "1", fontWeight: "bold" }}
        >
          Priming the Day
        </label>
      </div>

      <div className="upperDiv">
        <div className={"dateRow"}>
          <label htmlFor="loggedDate" className="score label">
            Diary Entry Date:
          </label>
          <input
            id="loggedDate"
            type="date"
            name="logDate"
            value={today.logDate}
            onChange={handleChange}
          />
        </div>
        <div className="scoring__row">
          <div className="score__row">
            <label htmlFor="beforeScore">
              Before filling in my NWD today, I feel
            </label>
            {today.startScore > 0 && (
              <label className="score__display">{today.startScore}</label>
            )}
          </div>

          <input
            id="beforeScore"
            className={today.startScore ? "weekSlider" : "weekSlider off"}
            type="range"
            min="0"
            max="5"
            step="0.1"
            defaultValue="0"
            name="startScore"
            value={today.startScore && today.startScore}
            style={{
              background: background.startScore && background.startScore,
            }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <SliderTooltip
            position={
              tooltipPosition &&
              tooltipPosition.startScore &&
              tooltipPosition.startScore.position &&
              tooltipPosition.startScore.position
            }
            score={today.startScore && today.startScore}
          />
        </div>
      </div>
      <div className={"yeartile--day"}>
        <div className={"tutorial access"}>
          <div className="iconDiv--day">
            <label htmlFor="thankYou" className="score label">
              Today, I would like to say thank you to...
            </label>
          </div>
        </div>
        <AutoResizeTextArea
          id="thankYou"
          className="formInput moment"
          wrap="soft"
          autoComplete="off"
          value={today.thankYou && today.thankYou}
          name="thankYou"
          placeholder="my body for carrying me through the day."
          onChange={handleChange}
        />
      </div>

      <div className={"yeartile--day"}>
        <div className={"tutorial access"}>
          <div className="iconDiv--day">
            <label htmlFor="selfLess" className="score label">
              I will detach from self today by...
            </label>
          </div>
        </div>

        <AutoResizeTextArea
          id="selfLess"
          className="formInput moment"
          wrap="soft"
          name="selfLess"
          autoComplete="off"
          value={today.selfLess && today.selfLess}
          placeholder="simply breathing and staying in the moment"
          onChange={handleChange}
        />
      </div>
      <div className={"yeartile--day"}>
        <div className={"tutorial access"}>
          <div className="iconDiv--day">
            <label htmlFor="mindBody" className="score label">
              I am pleased to live in my mind & body because...
            </label>
          </div>
        </div>

        <AutoResizeTextArea
          id="mindBody"
          className="formInput moment"
          wrap="soft"
          autoComplete="off"
          value={today.mindBody && today.mindBody}
          name="mindBody"
          placeholder="it is here. Here with me. Here in the world."
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default PrimingTile;
