// React
import React, { useState, useEffect } from "react";

// External Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

// Internal Components
import VideoComponent from "../Video/Video";
import { PreviousDay } from "../Icons/Icon";

// Styling
import "./carousel.css";

function VideoCarousel(props) {
  const [hover, setHover] = useState(false);

  return (
    <div className="carousel__outer">
      <div
        className="carousel--button"
        onClick={props.handleIndexChangeBackward}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <PreviousDay landingpage={hover} />
      </div>
      <div className="carousel">
        {props.data &&
          props.data.map((item, i) => (
            <div
              key={item.url}
              className={`carousel--item${i}`}
              onClick={() => {
                if (i === 0) {
                  props.handleIndexChangeBackward();
                } else if (i === 2) {
                  props.handleIndexChangeForward();
                }
              }}
            >
              <VideoComponent path={item.url} width="100%" state={0} num={0} />
              {/* <h1 className="carousel--header">{item.header}</h1> */}
            </div>
          ))}
      </div>
      <div
        className="carousel--button flipped"
        onClick={props.handleIndexChangeForward}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <PreviousDay landingpage={hover} />
      </div>
    </div>
  );
}

function ReviewCarousel(props) {
  const [reviews, setReviews] = useState([
    {
      name: "Louise O'Brien",
      job: "Account Director",
      text: '"No Wasted Days has made life so straightforward. Setting goals, organising my day and thinking about myself used to cause me such overwhelm. Now, I have a simple, and free, place to make sense of my day, my mind and make the most of life again!"',
      stars: 5,
      datetime: "8:35 PM - Jan 6, 2024",
      url: "https://images.unsplash.com/photo-1672527838035-90ae2ad2e813?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      alt: "Louise's Testimony",
    },
    {
      name: "Jamie Richardson",
      job: "Software Developer",
      text: '"I have tried so many physical journals. They are great to write in, but in terms of actually learning about yourself, there is no contest. No Wasted Days is so much more empowering and insightful!"',
      stars: 5,
      datetime: "7:12 AM - Mar 22, 2024",
      url: "https://images.unsplash.com/photo-1531750026848-8ada78f641c2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      alt: "Jamie's Testimony",
    },
    {
      name: "Jade Odquier",
      job: "Art Director",
      text: '"I feel, thanks to NoWastedDays.io, that I am so much more self-aware and happily so. It does not scare me anymore to learn about parts of myself."',
      stars: 5,
      datetime: "11:49 PM - April 4, 2024",
      url: "https://images.unsplash.com/photo-1530054733408-79162074a62b?q=80&w=2073&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      alt: "Jade's Testimony",
    },
    {
      name: "Jordan Thomas",
      job: "Video Editor",
      text: '"I am so glad that something like this is available to people. It is a god send in a world of social media and divisive content."',
      stars: 5,
      datetime: "6:07 PM - Jun 12, 2024",
      url: "https://images.unsplash.com/photo-1511806754518-53bada35f930?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      alt: "Jade's Testimony",
    },
  ]);
  const [filteredData, setFilteredData] = useState([]);
  const [hover, setHover] = useState(false);

  const StarRating = ({ rating }) => {
    const stars = Array.from({ length: rating }, (_, index) => (
      <span key={index}>
        {" "}
        <FontAwesomeIcon icon={faStar} style={{ color: "#e2dfcf" }} size="1x" />
      </span>
    ));
    return <div>{stars}</div>;
  };

  const filterArrayByWidth = (width) => {
    if (width < 1900 && width > 1300) {
      return reviews.filter((item, index) => index <= 2);
    } else if (width < 1300 && width > 800) {
      return reviews.filter((item, index) => index <= 1);
    } else if (width < 800) {
      return reviews.filter((item, index, idx) => index <= 1);
    } else {
      return reviews;
    }
  };

  const handleResize = () => {
    const width = Math.max(document.documentElement.clientWidth);
    const newFilteredData = filterArrayByWidth(width);
    setFilteredData(newFilteredData);
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [reviews, window]);

  return (
    <section className="review__section">
      <div className="button__wrapper">
        <div className="review__wrapper">
          {filteredData &&
            filteredData.map((rev, i) => (
              <div key={rev.name} className="review__outer">
                <div className="review__row">
                  <div className="review__img">
                    <img src={rev.url} alt="headshot" width="100%" />
                  </div>
                  <div className="review__name">
                    <p>{rev.name}</p>
                    <span>{rev.job}</span>
                  </div>
                </div>

                <div className="review__main">
                  <div className="review__text">
                    <p>{rev.text}</p>
                  </div>
                </div>
                <div className="review__row">
                  <span>{rev.datetime}</span>
                  <StarRating rating={rev.stars} />
                </div>
              </div>
            ))}
        </div>
        {/* <div
          className="carousel--button flipped"
          // onClick={props.handleIndexChangeForward}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
        >
          <PreviousDay landingpage={hover} />
        </div> */}
      </div>
    </section>
  );
}

export { VideoCarousel, ReviewCarousel };
