//React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

//Actions
import { listYears } from "../../actions/yearActions";
import { listWeeks } from "../../actions/weekActions";
import { listDays } from "../../actions/dayActions";
import { logout } from "../../actions/userActions";

//Internal Components
import HeaderSearch from "./HeaderSearch";
import DropdownLoading from "../../components/Loading/DropdownLoading";
import {
  ProfileIcon,
  YearIcon,
  WeekIcon,
  DiaryIcon,
  InsightsIcon,
  LogoutIcon,
  ValuesIcon,
  CreateDayIconV2,
  LoginIcon,
  RegisterIcon,
} from "../../components/Icons/Icon";
import MobileMenu from "./MobileMenu";

// Utilities
import dateFormatter from "../../utils/DateFormatter";

//External Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

//Images
import wavy_top from "../../images/Assets/PageBreakers/1920x100_Bottom.svg";

//Styling
import "./header.css";
import IconBlock from "./IconBlock";

function Header({ variant }) {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  // State
  const [homepage, setHomepage] = useState(
    window.location.href === "/" ? true : false
  );
  const [currentWindow, setCurrentWindow] = useState(window.location.href);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [userStatus, setUserStatus] = useState();
  const [pageLoading, setPageLoading] = useState(true);
  const [yearMap, setYearMap] = useState();
  const [weeksMap, setWeeksMap] = useState();
  const [daysMap, setDaysMap] = useState();
  const [viewWidth, setViewWidth] = useState(
    Math.max(document.documentElement.clientWidth)
  );
  const [insightTextState, setInsightTextState] = useState("Insights");
  const [headerSpan, setHeaderSpan] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [menuState, setMenuState] = useState("menu");
  const [navMenu, setNavMenu] = useState("navMenu");
  const [navDock, setNavDock] = useState("navDock");
  const [trialDays, setTrialDays] = useState();

  // Redux State Management
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const yearList = useSelector((state) => state.yearList);
  const { years } = yearList;

  const weekList = useSelector((state) => state.weekList);
  const { weeks } = weekList;

  const dayList = useSelector((state) => state.dayList);
  const { loading, error, days } = dayList;

  useEffect(() => {
    dispatch(listYears());
  }, [dispatch, userInfo]);

  useEffect(() => {
    dispatch(listWeeks());
  }, [dispatch, userInfo]);

  useEffect(() => {
    dispatch(listDays());
  }, [dispatch, userInfo]);

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
  };

  const yearMapChange = () => {
    const id =
      years &&
      years
        .filter((year, i, years) => years.indexOf(year) === years.length - 1)
        .map((year) => year._id);

    if (years && years.length >= 1) {
      setYearMap((yearMap) => `/year/${id}`);
    } else {
      setYearMap((yearMap) => `/year/create`);
    }
  };

  const weekMapChange = () => {
    const id =
      weeks &&
      weeks
        .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
        .map((week) => week._id);
    if (weeks && weeks.length > 0) {
      setWeeksMap((weeksMap) => `/week/${id}`);
    } else {
      setWeeksMap((weeksMap) => `/week/create`);
    }
  };

  const dayMapChange = async () => {
    let endScoreTrue = false;
    let id;

    if (Array.isArray(days) === true) {
      id =
        days &&
        (await days
          .filter((day, i, days) => days.indexOf(day) === days.length - 1)
          .map((day) => day._id));

      let testValue = await days
        .filter((day, i, days) => days.indexOf(day) === days.length - 1)
        .map((day) => day.endScore);

      if (testValue[0] === null || testValue[0] === undefined) {
        endScoreTrue = true;
      }
    }

    if (days && days.length > 0 && endScoreTrue) {
      setDaysMap((daysMap) => `/today/${id[0]}`);
    } else {
      setDaysMap((daysMap) => `/today`);
    }
  };

  const loadingTimeout = () => {
    var randNum = Math.random(1) * 3000 + Math.random(1) * 3000;
    setTimeout(() => {
      if (days) {
        setPageLoading(false);
      }
    }, randNum);
  };

  useEffect(() => {
    if (
      !userInfo ||
      !userInfo.subscription ||
      !userInfo.subscription.userStart
    ) {
      console.log("No User Data");
    } else {
      const trialStartDate = userInfo.subscription.userStart;
      const currentTier = userInfo.subscription.plan;

      if (currentTier === "trial") {
        const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
        const trialStartDateMs = new Date(trialStartDate).getTime();
        const trialStartDateStart = new Date(trialStartDate);
        let thirtyDaysAhead = new Date(trialStartDateStart);
        thirtyDaysAhead.setDate(trialStartDateStart.getDate() + 30);
        const differenceInMilliseconds = Math.abs(thirtyDaysAhead - Date.now());
        const differenceInDays = Math.ceil(
          differenceInMilliseconds / (1000 * 60 * 60 * 24)
        );

        const currentDateMs = new Date().getTime();

        setTrialDays(differenceInDays);
      } else if (currentTier !== "trial") {
        setTrialDays(0);
      }
    }
  }, []);

  useEffect(() => {
    loadingTimeout();
  }, []);

  const userFunction = () => {
    if (
      !userInfo ||
      !userInfo.subscription ||
      !userInfo.subscription.userStart
    ) {
      console.log("No User Data");
    } else if (
      userInfo.subscription.plan === "trial" ||
      userInfo.subscription.plan === "classic_annual" ||
      userInfo.subscription.plan === "classic_monthly" ||
      userInfo.subscription.plan === "core_annual" ||
      userInfo.subscription.plan === "core_monthly"
    ) {
      setUserStatus("no_coach");
    } else {
      setUserStatus("coach");
    }
  };

  useEffect(() => {
    userFunction();
  }, [userInfo]);

  useEffect(() => {
    dayMapChange();
  }, [days]);

  useEffect(() => {
    weekMapChange();
  }, [weeks]);

  useEffect(() => {
    yearMapChange();
  }, [years]);

  const svgIcons = [
    { Component: YearIcon, title: "Map", url: yearMap },
    { Component: WeekIcon, title: "Planner", url: weeksMap },
    { Component: DiaryIcon, title: "Journal", url: daysMap },
    { Component: InsightsIcon, title: "Insights", url: "/insights" },
    { Component: ProfileIcon, title: "My Account", url: "/my-account/menu" },
    { Component: LogoutIcon, title: "Logout", url: "" },
    { Component: RegisterIcon, title: "Register", url: "/register" },
    { Component: LoginIcon, title: "Login", url: "/login" },
  ];

  const openMenu = () => {
    if (menuState === "menu") {
      setMenuState((menuState) => "menu active");
      setNavMenu((navMenu) => "navMenu active");
      setNavDock((navDock) => "navDock");
    } else {
      setMenuState((menuState) => "menu");
      setNavMenu((navMenu) => "navMenu");
      setNavDock((navDock) => "navDock");
    }
  };

  const openDock = () => {
    if (menuState === "menu") {
      setMenuState((menuState) => "menu active");
      setNavDock((navDock) => "navDock active");
      setNavMenu((navMenu) => "navMenu");
    } else {
      setMenuState((menuState) => "menu");
      setNavDock((navDock) => "navDock");
      setNavMenu((navMenu) => "navMenu");
    }
  };

  return (
    <>
      <nav className={variant === "dark" ? `nav central dark` : `nav central`}>
        <div className="box left">
          {Array.isArray(days) === true ? (
            <a href={daysMap}>
              {variant === "dark" ? (
                <div
                  className={
                    variant === "dark" ? `navElement dark stack` : `navElement`
                  }
                >
                  <img
                    src="https://cdn.sanity.io/images/ou6d2gec/production/cd1a77ad4d64832bf1c08fedeb3c3701058809aa-493x336.svg"
                    alt="no wasted days"
                    width="42px"
                    height="28px"
                  ></img>
                </div>
              ) : (
                <ValuesIcon />
              )}
            </a>
          ) : (
            <a href={"/today"} target="_blank" rel="noopener noreferrer">
              <img
                src="https://cdn.sanity.io/images/ou6d2gec/production/87eec4d22fdeb99bdae99a9822c0eabb3ab18f36-1080x93.svg"
                alt="no wasted days"
                width="100%"
              ></img>
            </a>
          )}
        </div>
        <div className="box centre">
          {!pageLoading && <HeaderSearch data={days} variant={variant} />}
        </div>
        <div className="box right__desktop">
          {currentWindow.endsWith("/") ? (
            <IconBlock
              block={svgIcons.slice(-2)}
              variant={variant}
              trialDays={trialDays}
              userStatus={userStatus}
            />
          ) : (
            <IconBlock
              block={svgIcons.slice(0, -3)}
              variant={variant}
              trialDays={trialDays}
              userStatus={userStatus}
            />
          )}
        </div>
        <div className="box right__mobile" onClick={openMenu}>
          <FontAwesomeIcon id="burgerMenu" className="burger" icon={faBars} />
        </div>
      </nav>
      <MobileMenu
        menuState={menuState}
        navMenu={navMenu}
        pageLoading={pageLoading}
        data={days}
        daysMap={daysMap}
        weeksMap={weeksMap}
        yearMap={yearMap}
        currentWindow={currentWindow}
        logoutHandler={logoutHandler}
      />
    </>
  );
}

export default Header;
