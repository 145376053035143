// React
import React, { useState, useEffect, useMemo } from "react";

// External Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

// Utilities
import dateFormatter from "../../utils/DateFormatter";

function HeaderSearch({ variant, data }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [datesArray, setDatesArray] = useState();

  useEffect(() => {
    if (data) {
      let dArr = [];

      data &&
        data.map((data, index) =>
          dArr.push({
            id: data._id,
            date: dateFormatter(data.logDate.substring(0, 10)),
          })
        );

      setDatesArray(dArr);
    }
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterDates = () => {
    let list =
      datesArray &&
      datesArray.length > 0 &&
      datesArray.filter((day) =>
        day.date.toLowerCase().includes(searchQuery.toLowerCase())
      );

    return list;
  };

  const visibleDays = useMemo(() => filterDates(), [searchQuery]);

  return (
    <>
      <div className="navWrapper">
        <a>
          <div
            className={
              variant === "dark" ? `navElement dark stack` : `navElement`
            }
          >
            <div className="header node white">
              <FontAwesomeIcon
                style={
                  variant === "dark"
                    ? { color: "#004a22" }
                    : { color: "#e2dfcf" }
                }
                className="icon green"
                icon={faMagnifyingGlass}
              />
            </div>

            <input
              className={
                variant === "dark" ? `searchInput dark` : `searchInput`
              }
              type="search"
              placeholder="Search your journal"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </a>
        {searchQuery && (
          <ul className={variant === "dark" ? `searchList dark` : `searchList`}>
            {visibleDays?.map((d) => (
              <li
                className={
                  variant === "dark" ? `searchItem dark` : `searchItem`
                }
                key={d.id}
              >
                <a
                  id={variant === "dark" ? `searchItemDark` : `searchItem`}
                  href={`/today/${d.id}`}
                >
                  {d.date}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
}

export default HeaderSearch;
