// React
import React from "react";

// Internal Components
import DropdownLoading from "../../components/Loading/DropdownLoading";

function Icon({ url, title, children, trialDays, variant, userStatus }) {
  return (
    <a href={url}>
      <div
        className={variant === "dark" ? `navElement dark stack` : `navElement`}
      >
        <div className="header node white">{children}</div>
        <h6>{title}</h6>
        {title === "Insights" &&
          trialDays !== 0 &&
          trialDays <= 30 &&
          userStatus === "no_coach" && (
            <span style={{ fontSize: "0.6em", fontWeight: "200" }}>
              Ad Free Days Left: {trialDays}
            </span>
          )}
      </div>
    </a>
  );
}

function IconBlock({ block, pageLoading, variant, trialDays }) {
  return pageLoading ? (
    <a>
      <DropdownLoading />
    </a>
  ) : (
    block?.map((icon, i) => {
      let { Component, url, title } = icon;
      return (
        <Icon
          key={i}
          url={url}
          title={title}
          trialDays={trialDays}
          variant={variant}
        >
          <Component />
        </Icon>
      );
    })
  );
}

export default IconBlock;
