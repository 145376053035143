// React
import React from "react";

// Internal Components
import DropdownLoading from "../../components/Loading/DropdownLoading";

function MobileMenu({
  menuState,
  navMenu,
  pageLoading,
  daysMap,
  weeksMap,
  yearMap,
  data,
  currentWindow,
  logoutHandler,
}) {
  return (
    <div className={menuState}>
      <ul className={navMenu}>
        <li className="listItem">
          {pageLoading ? (
            <a id="nava">
              <DropdownLoading />
            </a>
          ) : (
            <a id="nava" href={yearMap}>
              <strong>Map</strong>
            </a>
          )}
        </li>
        <li className="listItem">
          {pageLoading ? (
            <a id="nava">
              <DropdownLoading />
            </a>
          ) : (
            <a id="nava" href={weeksMap}>
              <strong>Planner</strong>
            </a>
          )}
        </li>
        <li className="listItem">
          {pageLoading ? (
            <a id="nava">
              <DropdownLoading />
            </a>
          ) : Array.isArray(data) === true ? (
            <a id="nava" href={daysMap}>
              <strong>Journal</strong>
            </a>
          ) : (
            <a id="nava" href={"/today"}>
              <strong>Journal</strong>
            </a>
          )}
        </li>

        <li className="listItem">
          {pageLoading ? (
            <a id="nava">
              <DropdownLoading />
            </a>
          ) : currentWindow.endsWith("/my-account/menu") ? (
            <a id="nava" onClick={logoutHandler}>
              <strong>Log out</strong>{" "}
            </a>
          ) : data && data.length > 7 ? (
            <a id="nava" href={"/insight"}>
              <strong>Insight</strong>
            </a>
          ) : (
            <a id="nava" href={daysMap}>
              <strong>
                <s>Insight</s>
              </strong>
            </a>
          )}
        </li>
        <li className="listItem">
          {pageLoading ? (
            <a id="nava">
              <DropdownLoading />
            </a>
          ) : (
            <a id="nava" href={"/my-account/menu"}>
              <strong>My Account</strong>
            </a>
          )}
        </li>
        <li className="listItem">
          {pageLoading ? (
            <a id="nava">
              <DropdownLoading />
            </a>
          ) : (
            <a id="nava" href={"/contact/bug_found"}>
              <strong>Report a bug</strong>
            </a>
          )}
        </li>
      </ul>
    </div>
  );
}

export default MobileMenu;
