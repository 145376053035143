//React
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLinesVertical } from "@fortawesome/free-solid-svg-icons";

//Internal Components
import {
  CreateDayIconV2,
  ValuesIcon,
  IkigaiIcon,
  VisionIcon,
  QuoteIconV2,
  HideOverlayIcon,
  QuestionIcon,
  SaveIcon,
  ProfileIcon,
} from "../../components/Icons/Icon";

// Styling
import "./dock.css";

export default function HideDock({ handleSubmit, viewWidth }) {
  const navigate = useNavigate();

  const [helpNodeState, setHelpNodeState] = useState("hidedock node inactive");
  const [colorState, setColorState] = useState({
    one: "cls-1-invert",
    two: "cls-2-invert",
  });
  const [currentWindow, setCurrentWindow] = useState(window.location.href);
  const [isAnimatingTutorial, setIsAnimatingTutorial] = useState();
  const [isAnimatingSave, setIsAnimatingSave] = useState();

  return (
    <div className="hidedock--container">
      {currentWindow.endsWith("/") ||
      currentWindow.endsWith("/insight") ||
      currentWindow.endsWith("/profile") ? (
        <>
          <div className={`hidedock node`} data-tooltip="Start a New Day">
            <CreateDayIconV2 />
          </div>

          <div className="hidedock separator">
            <FontAwesomeIcon
              id="separatorIcon"
              className="separator"
              icon={faGripLinesVertical}
            />
          </div>

          {viewWidth > 500 ? (
            <div className={`hidedock node`} data-tooltip="Click for Help">
              <QuestionIcon />
            </div>
          ) : (
            <div
              className={`hidedock node ${
                isAnimatingTutorial ? "animate" : ""
              }`}
              data-tooltip="Click for Help"
            >
              <QuestionIcon />
            </div>
          )}
          {currentWindow.endsWith("/profile") ? (
            <></>
          ) : (
            <div className={`hidedock node`} data-tooltip="Your Profile">
              <ProfileIcon />
            </div>
          )}

          <div className={`hidedockwide node`} data-tooltip="Art of Looking">
            <VisionIcon />
          </div>
        </>
      ) : currentWindow.endsWith("/create") ? (
        currentWindow.endsWith("/year/create") ? (
          <>
            <div
              className={`hidedock node`}
              data-tooltip="Try the NWD Values Process"
            >
              <ValuesIcon />
            </div>
            <div
              className={`hidedockwide node`}
              data-tooltip="Vision Process Coming Soon."
              style={{ opacity: "0.25" }}
            >
              <VisionIcon />
            </div>

            <div
              className={`hidedock node`}
              data-tooltip="Ikigai Process Coming Soon."
              style={{ opacity: "0.25" }}
            >
              <IkigaiIcon />
            </div>

            <div
              className={`hidedock node`}
              data-tooltip="Quote Finder Coming Soon."
              style={{ opacity: "0.25" }}
            >
              <QuoteIconV2 />
            </div>
            <div className="hidedock separator">
              <FontAwesomeIcon
                id="separatorIcon"
                className="separator"
                icon={faGripLinesVertical}
              />
            </div>
            <div
              className={`hidedock node_save`}
              data-tooltip={"Save your new values"}
            >
              <SaveIcon colorStateTwo={colorState.two} />
            </div>
          </>
        ) : (
          <>
            <div className={`hidedock node`} data-tooltip="Toggle Help">
              <HideOverlayIcon />
            </div>

            <div className={`hidedock node_save`} data-tooltip="Click to Save">
              <SaveIcon colorStateTwo={colorState.two} />
            </div>

            <div className={`hidedock node`} data-tooltip="Click for Tutorial">
              <QuestionIcon />
            </div>
          </>
        )
      ) : currentWindow.match("/year/") ? (
        <>
          <div className={`hidedock node`} data-tooltip="Click for Tutorial">
            <QuestionIcon />
          </div>

          <div className="hidedock separator">
            <FontAwesomeIcon
              id="separatorIcon"
              className="separator"
              icon={faGripLinesVertical}
            />
          </div>

          <div
            className={`hidedock node`}
            data-tooltip="Try the NWD Values Process"
          >
            <ValuesIcon />
          </div>

          <div
            className={`hidedockwide node`}
            data-tooltip="Vision Process Coming Soon."
            style={{ opacity: "0.25" }}
          >
            <VisionIcon />
          </div>

          <div
            className={`hidedock node`}
            data-tooltip="Ikigai Process Coming Soon."
            style={{ opacity: "0.25" }}
          >
            <IkigaiIcon />
          </div>

          <div
            className={`hidedock node`}
            data-tooltip="Quote Finder Coming Soon."
            style={{ opacity: "0.25" }}
          >
            <QuoteIconV2 />
          </div>

          <div className="hidedock separator">
            <FontAwesomeIcon
              id="separatorIcon"
              className="separator"
              icon={faGripLinesVertical}
            />
          </div>

          <div
            className={`hidedock node_save`}
            data-tooltip={
              isAnimatingSave ? "Save your new values" : "Click to Save"
            }
          >
            <SaveIcon colorStateTwo={colorState.two} onClick={handleSubmit} />
          </div>
        </>
      ) : (
        <>
          <div className={`jordandock node_save`} data-tooltip="Toggle Help">
            <HideOverlayIcon />
          </div>
          <div
            className={`jordandock node_save`}
            data-tooltip="Click to Save"
            onClick={handleSubmit}
          >
            <SaveIcon colorStateTwo={colorState.two} />
          </div>
          <a href="/week/create">
            <div className={`dock node`} data-tooltip="Start a New Week">
              <CreateDayIconV2 />
            </div>
          </a>
          <div className={`hidedock node`} data-tooltip="Click for Help">
            <QuestionIcon />
          </div>
        </>
      )}
    </div>
  );
}
