// React
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

// Internal Components
//// Features
import MidDock from "../../features/dock/MidDock";
import FullDock from "../../features/dock/FullDock";

//// Layout
import PageContainer from "../../layouts/PageContainer";
import TileContainer from "../../features/tiles/TileContainer";
import Header from "../../layouts/Header/Header";
import Footer from "../../layouts/Footer/TestFooter";

//// Utils
import generateSecret from "../../utils/generateSecret";

// External Components
import CryptoJS from "crypto-js";

function DayScreen() {
  // Hooks
  const yearList = useSelector((state) => state.yearList);
  const { years } = yearList;

  const weekList = useSelector((state) => state.weekList);
  const { weeks } = weekList;

  // State
  //// Data
  const [today, setToday] = useState({});
  const [latestYear, setLatestYear] = useState();
  const [objectives, setObjectives] = useState();

  //// Layout
  const [viewWidth, setViewWidth] = useState(
    Math.round(Math.max(document.documentElement.clientWidth))
  );
  const [tooltipPosition, setTooltipPosition] = useState({});
  const [background, setBackground] = useState({});
  const [continuation, setContinuation] = useState();
  const [cornerState, setCornerState] = useState({});

  // Functions
  //// Data Transformations

  useEffect(() => {}, []);

  useEffect(() => {
    const fetching = async () => {
      const key_response = await fetch("https://nwd-backend.herokuapp.com/key")
        .then(async (r) => {
          if (!r.ok) {
            throw new Error("Failed to fetch config");
          }

          return r;
        })
        .catch((error) => {
          console.log("Error:", error);
        });

      try {
        let weekdata = weeks
          ? weeks.length === 1
            ? await weeks[0]
            : await weeks
                .filter(
                  (week, i, weeks) => weeks.indexOf(week) === weeks.length - 1
                )
                .pop()
          : {};

        if (
          typeof weekdata === "object" &&
          weekdata !== null &&
          weekdata &&
          key_response.ok
        ) {
          setObjectives(
            JSON.parse(
              CryptoJS.AES.decrypt(weekdata.objectives, key_response).toString(
                CryptoJS.enc.Utf8
              )
            )
          );

          let yeardata =
            years &&
            years.filter(
              (year, i, years) => years.indexOf(year) === years.length - 1
            );

          setLatestYear(yeardata);

          // setObjectiveOne_score(Number(data.objectiveOne_score));
          // setObjectiveTwo_score(Number(data.objectiveTwo_score));
          // setObjectiveThree_score(Number(data.objectiveThree_score));
          // setObjectiveFour_score(Number(data.objectiveFour_score));
          // setObjectiveFive_score(Number(data.objectiveFive_score));
          // setObjectiveSix_score(Number(data.objectiveSix_score));
          // setObjectiveSeven_score(Number(data.objectiveSeven_score));
          // setObjectiveEight_score(Number(data.objectiveEight_score));
          // setObjectiveNine_score(Number(data.objectiveNine_score));
          // setObjectiveTen_score(Number(data.objectiveTen_score));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetching();
  }, [weeks]);

  const handleSliderChange = (event, num) => {
    const sliderValue = event.target.value;
    const sliderName = event.target.name;

    // Calculate the tooltip position based on the slider value
    const sliderWidth = event.target.clientWidth - 15;

    const newPercent = sliderValue / num;
    const newPosition = sliderWidth * newPercent;

    setTooltipPosition((prevState) => {
      let shallowCopy = { ...prevState };

      if (!shallowCopy[sliderName]) {
        // Add to existing state
        shallowCopy[sliderName] = { position: newPosition + 20, opacity: 1 };
      } else {
        // Copy previous state, change individual obj,
        shallowCopy[sliderName] = { position: newPosition + 20, opacity: 1 };
      }

      return shallowCopy;
    });

    const percent = (sliderValue / num) * 100;

    setBackground({
      ...background,
      [sliderName]: `linear-gradient(to right, rgba(0, 74, 35, 0.5) ${percent}%, transparent ${percent}%)`,
    });
  };

  const handleChange = useMemo(() => {
    return (e) => {
      setToday({
        ...today,
        [e.target.name]: e.target.value,
      });

      // if (e.target.name === "startScore" || e.target.name === "endScore") {
      //   handleSliderChange(e, 5);
      // }

      // if (e.target.name.startsWith("mom")) {
      //   handleSliderChange(e, 10);
      // }
    };
  }, [today]);

  // const contFunc = () => {
  //   const birthDate =
  //     years &&
  //     years
  //       .filter((year, i, years) => years.indexOf(year) === years.length - 1)
  //       .map((year) => year.birthDate.substring(0, 10));
  //   if (birthDate) {
  //     if (logDate) {
  //       let day1_ = new Date(birthDate);
  //       let day2_ = new Date(logDate);
  //       let diff_ = Math.abs(day2_ - day1_);
  //       let daysCalc_ = diff_ / (1000 * 3600 * 24);
  //       let days1_ = Math.floor(daysCalc_);
  //       setContinuation(days1_);
  //     } else {
  //       let day1 = new Date(birthDate);
  //       let day2 = new Date();
  //       let diff = Math.abs(day2 - day1);
  //       let daysCalc = diff / (1000 * 3600 * 24);
  //       let days1 = Math.floor(daysCalc);
  //       setContinuation(days1);
  //     }
  //   } else {
  //     setContinuation("");
  //   }
  // };

  useEffect(() => {
    console.log(today);
    // console.log(background);
    // console.log(tooltipPosition);
  }, [today]);

  return (
    <>
      <Header variant="light" />
      {/* Mouse Tooltip  */}
      {/* Tutorial Window  */}
      {/* <MidDock /> */}
      {/* Objectives  */}
      <TileContainer
        className="updateday--container"
        today={today}
        handleChange={handleChange}
        background={background}
        tooltipPosition={tooltipPosition}
        continuation={continuation}
      />
      <Footer />
    </>
  );
}

export default DayScreen;
