// React
import React, { useState } from "react";

// Layout Components
import Header from "../../layouts/Header/Header";
import PageContainer from "../../layouts/PageContainer";
import TestFooter from "../../layouts/Footer/TestFooter";
import TileContainer from "../../features/tiles/TileContainer";

function PracticeScreenTwo() {
  const [practice, setPractice] = useState({});

  const handleChange = (e) => {
    let sliderValue = e.target.value;
    let sliderName = e.target.name;

    setPractice((prevState) => {
      let shallowState = { ...prevState };

      if (!shallowState[sliderName]) {
        shallowState[sliderName] = sliderValue;
      } else {
        shallowState[sliderName] = sliderValue;
      }

      return shallowState;
    });
  };

  console.log(practice);

  return (
    <>
      <Header variant="light" />
      <PageContainer className="main__container">
        <TileContainer />
      </PageContainer>
      <TestFooter />
    </>
  );
}

export default PracticeScreenTwo;
