// React
import React, { useState } from "react";

// Internal Components
import AutoResizeTextArea from "../../components/AutoResizeTextarea/AutosizeTextarea";
import SliderTooltip from "../../components/Tooltips/Tooltips";

function ProcessingTile({ today, background, tooltipPosition, handleChange }) {
  // State
  const [viewWidth, setViewWidth] = useState(0);
  const [cornerState, setCornerState] = useState({
    endScore: "cornerStateOff",
  });

  return (
    <div className={"tile right"}>
      <div className="dateRow--header">
        <label
          className="score label"
          style={{ opacity: "1", fontWeight: "bold" }}
        >
          Reflecting on the Day
        </label>
      </div>

      <div className={"yeartile--day"}>
        <div className={"tutorial access"}>
          <div className="iconDiv--day">
            <label htmlFor="rememberToday" className="score label">
              The memories from today that will capture it forever are...
            </label>
          </div>
        </div>

        <AutoResizeTextArea
          id="rememberToday"
          className="formInput moment"
          wrap="soft"
          autoComplete="off"
          value={today.rememberToday && today.rememberToday}
          name="rememberToday"
          placeholder={
            "A great morning of stretching and a cup of tea; tough day at work, but a good chat - needed that."
          }
          onChange={handleChange}
        />
      </div>

      <div className={"yeartile--day"}>
        <div className={"tutorial access"}>
          <div className="iconDiv--day">
            <label htmlFor="leaveBehind" className="score label">
              The thoughts & feelings from today I would like to leave behind
              are...
            </label>
          </div>
        </div>

        <AutoResizeTextArea
          id="leaveBehind"
          className="formInput moment"
          wrap="soft"
          autoComplete="off"
          value={today.leaveBehind && today.leaveBehind}
          name="leaveBehind"
          placeholder={"Not being patient"}
          onChange={handleChange}
        />
      </div>

      <div className={"yeartile--day"}>
        <div className={"tutorial access"}>
          <div className="iconDiv--day">
            <label htmlFor="leaveBehind" className="score label">
              and finally, anything else that is on my mind
            </label>
          </div>
        </div>

        <AutoResizeTextArea
          id="onMyMind"
          className="formInput moment"
          wrap="soft"
          autoComplete="off"
          value={today.onMyMind && today.onMyMind}
          name="onMyMind"
          placeholder={"Not an easy day today."}
          onChange={handleChange}
        />
      </div>

      <div className="scoring__row" style={{ borderBottom: "none" }}>
        <div
          className={"score__row"}
          style={
            today.endScore === "5" ||
            (today.endScore === undefined && viewWidth < 425)
              ? { borderRadius: "5px 5px 0px 0px" }
              : { borderRadius: "5px 5px 5px 0px" }
          }
        >
          <label htmlFor="endScore">
            After the day described above, I feel...
          </label>
          {today.endScore > 0 && (
            <label className="score__display">{today.endScore}</label>
          )}
        </div>
        <input
          id="endScore"
          className={today.endScore ? "weekSlider" : "weekSlider off"}
          type="range"
          min="0"
          max="5"
          step="0.1"
          defaultValue="0"
          style={{
            background: background.endScore && background.endScore,
          }}
          value={today.endScore && today.endScore}
          name="endScore"
          onChange={handleChange}
        />
        <SliderTooltip
          position={
            tooltipPosition &&
            tooltipPosition.endScore &&
            tooltipPosition.endScore.position &&
            tooltipPosition.endScore.position
          }
          score={today.endScore && today.endScore}
        />
      </div>
    </div>
  );
}

export default ProcessingTile;
