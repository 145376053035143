// React
import React, { useState, useEffect } from "react";

// External Components
import { motion } from "framer-motion";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../Dropable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faSquareCaretDown,
  faSquareCaretUp,
  faSquareXmark,
  faTrash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

function Callout({
  id,
  steps,
  description,
  notes,
  overlay,
  calloutActive,
  columnId,
  subTaskIndex,
  onDragEndSteps,
  addStep,
  handleCalloutDisplay,
  handleIndexChange,
  updateDescription,
  updateStep,
  updateNotes,
  toggleStep,
  toggleOverlay,
  deleteStep,
  deleteObjective,
  deleteSubtask,
  stepsLength,
}) {
  const [warning, setWarning] = useState(false);

  useEffect(() => {
    console.log(steps);
  });

  return (
    <>
      <motion.div
        className={
          calloutActive
            ? `objective__callout--active ${columnId}`
            : "objective__callout"
        }
        style={{ zIndex: `3${subTaskIndex}` }}
      >
        <div className="callout__header">
          <input
            className="callout__header--input"
            type="text"
            value={description}
            autoComplete="off"
            onChange={(event) =>
              updateDescription(event, columnId, subTaskIndex)
            }
          ></input>

          <FontAwesomeIcon
            className="callout--icon"
            icon={faSquareXmark}
            onClick={() => {
              handleCalloutDisplay();
              toggleOverlay(false);
            }}
          />
        </div>

        <DragDropContext
          onDragEnd={(result) => onDragEndSteps(result, columnId, subTaskIndex)}
        >
          <StrictModeDroppable
            droppableId={`Steps-List-${columnId}-${subTaskIndex}`}
            type="STEPS"
          >
            {(provided) => (
              <div
                className="callout__actions"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {steps &&
                  steps.length > 0 &&
                  steps.map((d, i) => (
                    <Draggable key={d.id} draggableId={d.id} index={i}>
                      {(provided) => (
                        <div
                          className="objective__col--wrapper"
                          style={{ width: "100%" }}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div
                            className={
                              d.completed === 0
                                ? "objective__col--task"
                                : "objective__col--task-active"
                            }
                          >
                            <div
                              className={
                                d.completed === 0
                                  ? "task__radio"
                                  : "task__radio--active"
                              }
                              onClick={() =>
                                toggleStep(subTaskIndex, i, columnId)
                              }
                            ></div>
                            <textarea
                              type="text"
                              className={
                                d.completed === 0
                                  ? "task--input"
                                  : "task--input completed"
                              }
                              value={d.action}
                              onChange={(event) =>
                                updateStep(columnId, subTaskIndex, i, event)
                              }
                            />
                            <FontAwesomeIcon
                              className="objective__col--icon trash"
                              icon={faTrash}
                              onClick={() => {
                                deleteStep(columnId, subTaskIndex, i);
                              }}
                            />
                          </div>
                          {/* <div className="reorder">
                            <FontAwesomeIcon
                              onClick={() =>
                                props.handleStepReorder(
                                  props.columnId,
                                  index,
                                  i,
                                  "up"
                                )
                              }
                              className="objective__col--reorder"
                              icon={faSquareCaretUp}
                            />
                            <FontAwesomeIcon
                              onClick={() =>
                                props.handleStepReorder(
                                  props.columnId,
                                  index,
                                  i,
                                  "down"
                                )
                              }
                              className="objective__col--reorder"
                              icon={faSquareCaretDown}
                            />
                          </div> */}
                          {/* {provided.placeholder} */}
                        </div>
                      )}
                    </Draggable>
                  ))}

                {provided.placeholder}
                <div className="objective__col--name">
                  <FontAwesomeIcon
                    className="objective__col--icon"
                    icon={faPlus}
                  />
                  <input
                    type="text"
                    className="col__name--input"
                    placeholder="Add a new step"
                    autoComplete="off"
                    name={`${columnId}-${subTaskIndex}`}
                    // onChange={(e) => setAction(e.target.value)}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" && !event.repeat) {
                        event.preventDefault();
                        addStep(event, columnId, id, stepsLength);
                      }
                    }}
                  />
                  <FontAwesomeIcon
                    className="objective__col--icon ellipsis"
                    icon={faEllipsisVertical}
                  />
                </div>
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
        <div className="callout__notes">
          <textarea
            className="notes"
            placeholder="Write notes"
            value={notes}
            onChange={(event) => updateNotes(columnId, subTaskIndex, event)}
          />

          <div
            className="callout__button"
            onClick={() => {
              deleteSubtask(columnId, subTaskIndex);
              handleCalloutDisplay();
            }}
          >
            Delete Task
          </div>
        </div>
      </motion.div>
      <div
        className={warning ? "warning__callout--active" : "warning__callout"}
      >
        <strong>Are you sure you want to delete this objective?</strong>
        <div className="warning__button--row">
          <div
            className="callout__button"
            onClick={() => {
              deleteObjective(columnId);
              setWarning(false);
            }}
          >
            Yes
          </div>
          <div className="callout__button" onClick={() => setWarning(false)}>
            No
          </div>
        </div>
      </div>
    </>
  );
}

export default Callout;
