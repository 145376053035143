// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

// Actions
import { createYearAction, listYears } from "../../actions/yearActions";

// Internal Components
import TileLoading from "../../components/Loading/TileLoading";
import Header from "../../layouts/Header/Header";
import HideDock from "../../features/dock/HideDock";
import TestFooter from "../../layouts/Footer/TestFooter";
import {
  ValuesIcon,
  DobIcon,
  MemoryIcon,
  VisionIcon,
  IkigaiIcon,
  QuoteIconV2,
  QuestionIcon,
  VitIcon,
} from "../../components/Icons/Icon";
import PageContainer from "../../layouts/PageContainer";
import generateSecret from "../../utils/generateSecret";
import YearMap from "../../features/yearmap/YearMap";

// External Packages
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp91 } from "@fortawesome/free-solid-svg-icons";
import CryptoJS from "crypto-js";

// CSS
import "./yearscreen.css";

export default function YearScreen({ history }) {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State
  //// Initialise
  const [pageLoading, setPageLoading] = useState(true);
  const [viewWidth, setViewWidth] = useState(1400);
  const [me_year, setMe_year] = useState({
    // name: "Richard Brookman",
    // birthdate: "",
    // values: {
    //   one: {
    //     title: "Effort",
    //     description: "ABC",
    //   },
    //   two: {
    //     title: "Effort",
    //     description: "ABC",
    //   },
    //   three: {
    //     title: "Effort",
    //     description: "ABC",
    //   },
    //   four: {
    //     title: "Effort",
    //     description: "ABC",
    //   },
    //   five: {
    //     title: "Effort",
    //     description: "ABC",
    //   },
    // },
    // vision: {
    //   one: {
    //     description: "ABC def",
    //   },
    //   two: {
    //     description: "ABC def",
    //   },
    //   three: {
    //     description: "ABC def",
    //   },
    //   four: {
    //     description: "ABC def",
    //   },
    //   five: {
    //     description: "ABC def",
    //   },
    // },
    // ikigai: "abc def",
    // continuation: 12345,
    // quote: "abc def",
    // memory: "abc def",
  });

  //// Management
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const yearCreate = useSelector((state) => state.yearCreate); //
  const { loading, error, years } = yearCreate;

  let fetching = async () => {
    const response = await fetch("/key")
      .then(async (r) => {
        if (!r.ok) {
          throw new Error("Failed to fetch key");
        }

        let { code } = await r.json();

        return code;
      })
      .catch((error) => {
        console.log("Error:", error);
      });
    return response;
  };

  const handleSubmit = async () => {
    // const key = await fetching();
    const key = generateSecret().toString();

    dispatch(
      createYearAction(
        CryptoJS.AES.encrypt(JSON.stringify(me_year), key).toString()
      )
    )
      .then((response) => {
        if (response) {
          navigate(`/year/create`);
        } else {
          console.log("something still not right");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (e, tile_name) => {
    let { name, value, title } = e.target;
    // console.log(dataset);
    let targetTile = tile_name;
    let targetName = name;
    let targetTitle = title;
    let inputValue = value;

    console.log(targetTile, targetTitle, targetName);

    setMe_year((prevYear) => {
      let shallowYear = { ...prevYear };

      if (targetTile && targetTile.startsWith("v")) {
        shallowYear[targetTile] = {
          ...(shallowYear[targetTile] || {}),

          [targetName]: {
            ...(shallowYear[targetTile]?.[targetName] || {}),
            [targetTitle]: inputValue,
          },
        };

        return shallowYear;
      } else {
        shallowYear[targetTile] = inputValue;
        return shallowYear;
      }
    });
  };

  const loadingTimeout = () => {
    var randNum = Math.random(1) * 3000 + Math.random(1) * 3000 + 6000;
    setTimeout(() => {
      setPageLoading(false);
    }, randNum);
  };

  useEffect(() => {
    loadingTimeout();
    console.log(me_year);
  }, [me_year]);

  return (
    <>
      <>
        <Helmet>
          <title>Create | Year</title>
        </Helmet>
      </>
      <Header />
      <form onSubmit={handleSubmit}>
        <PageContainer className="yearContainer">
          <HideDock handleSubmit={handleSubmit} viewWidth={viewWidth} />
          {pageLoading ? (
            <TileLoading />
          ) : (
            <YearMap year={me_year} handleChange={handleChange} />
          )}
        </PageContainer>
      </form>
      <TestFooter />
    </>
  );
}
