// React
import React, { useEffect, useState } from "react";

// External Packages
import { Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";

// Internal Components
import {
  OneIcon,
  TwoIcon,
  ThreeIcon,
  FourIcon,
  FiveIcon,
  SixIcon,
  SevenIcon,
  EightIcon,
  NineIcon,
  TenIcon,
  SaveIcon,
  TickIcon,
  PreviousDay,
} from "../../../components/Icons/Icon";
import WeekLoading from "../../../components/Loading/WeekLoading";

import QuoteList from "../Styles/List";

const Column = (props) => {
  const title = props.title;
  const index = props.index;
  const objNum = props.index + 1;

  const [pageLoading, setPageLoading] = useState(false);
  const [warning, setWarning] = useState(false);

  useEffect(() => {
    const loadingTimeout = () => {
      var randNum = Math.random(1) * 3000 + Math.random(1) * 3000;
      setTimeout(() => {
        if (title === "" || title !== "") {
          setPageLoading(false);
        }
      }, randNum);
    };

    loadingTimeout();
  }, [props.objective, index]);

  useEffect(() => {
    console.log(props.objective);
  });

  return (
    <>
      {pageLoading ? (
        <WeekLoading num={objNum} />
      ) : (
        <Draggable draggableId={title} index={index}>
          {(provided, snapshot) => (
            <div
              className={
                props.objective.title === ""
                  ? `objective__col hideColumn`
                  : `objective__col`
              }
              ref={provided.innerRef}
              {...provided.draggableProps}
              // style={{ overflowY: "hidden" }}
            >
              <div
                className="objective__col--name"
                isdragging={snapshot.isdragging}
                {...provided.dragHandleProps}
              >
                <div className="col__name--icon">
                  {objNum === 1 && <OneIcon />}
                  {objNum === 2 && <TwoIcon />}
                  {objNum === 3 && <ThreeIcon />}
                  {objNum === 4 && <FourIcon />}
                  {objNum === 5 && <FiveIcon />}
                  {objNum === 6 && <SixIcon />}
                  {objNum === 7 && <SevenIcon />}
                  {objNum === 8 && <EightIcon />}
                  {objNum === 9 && <NineIcon />}
                  {objNum === 10 && <TenIcon />}
                </div>
                <div className="col__name--trash">
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => setWarning(true)}
                  />
                </div>
                <input
                  aria-label={`${props.objective.title} quote list`}
                  type="text"
                  className="col__name--input"
                  value={props.objective.title}
                  placeholder="Objective Name"
                  name={`${title}`}
                  onChange={(event) => props.updateTitle(event, props.order)}
                  autoComplete="off"
                ></input>
              </div>
              <QuoteList
                listId={title}
                listType="QUOTE"
                style={{
                  backgroundColor: snapshot.isDragging
                    ? "rgba(0,74,34,0.1)"
                    : null,
                }}
                overlay={props.overlay}
                title={title}
                index={index}
                tasks={props.objective.subTasks}
                internalScroll={props.isScrollable}
                isCombineEnabled={Boolean(props.isCombineEnabled)}
                useClone={Boolean(props.useClone)}
                onDragEndSteps={props.onDragEndSteps}
                addStep={props.addStep}
                toggleSubTask={props.toggleSubTask}
                toggleStep={props.toggleStep}
                toggleOverlay={props.toggleOverlay}
                updateDescription={props.updateDescription}
                updateStep={props.updateStep}
                updateNotes={props.updateNotes}
                deleteStep={props.deleteStep}
                deleteObjective={props.deleteObjective}
                deleteSubtask={props.deleteSubtask}
              />
              <div className="objective__col--name">
                <FontAwesomeIcon
                  className="objective__col--icon"
                  icon={faPlus}
                />
                <input
                  type="text"
                  className="col__name--input"
                  placeholder="Add new action"
                  name={`${title}`}
                  autoComplete="off"
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && event.target.value !== "") {
                      event.preventDefault();
                      props.addSubTask(event, props.objective.subTasks.length);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </Draggable>
      )}
      <div
        className={warning ? "warning__callout--active" : "warning__callout"}
      >
        <strong>Are you sure you want to delete this objective?</strong>
        <div className="warning__button--row">
          <div
            className="callout__button"
            onClick={() => {
              props.deleteObjective(title);
              setWarning(false);
            }}
          >
            Yes
          </div>
          <div className="callout__button" onClick={() => setWarning(false)}>
            No
          </div>
        </div>
      </div>
    </>
  );
};

export default Column;
