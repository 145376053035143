// React
import React, { useState, useEffect, useRef } from "react";

// External Components
import styled from "@xstyled/styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faSquareCaretDown,
  faSquareCaretUp,
  faSquareXmark,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../Dropable";

// Internal Components
import { borderRadius, grid } from "./Constants";
import Callout from "./Callout";

const getBackgroundColor = (isdragging, isGroupedOver, authorColors) => {
  if (isdragging) {
    return authorColors.soft;
  }

  if (isGroupedOver) {
    return "#004a22";
  }

  return "#f5f5f5";
};

const imageSize = 40;

const CloneBadge = styled.div`
  background: #79f2c0;
  bottom: ${grid / 2}px;
  border: 2px solid #57d9a3;
  border-radius: 50%;
  box-sizing: border-box;
  font-size: 10px;
  position: absolute;
  right: -${imageSize / 3}px;
  top: -${imageSize / 3}px;
  transform: rotate(40deg);
  height: ${imageSize}px;
  width: ${imageSize}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.a`
  user-select: none;
  text-decoration: none;
  /* anchor overrides */
  color: #091e42;

  &:hover,
  &:active {
    color: #004a22;
    text-decoration: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }

  /* flexbox */
  display: flex;
`;

function getStyle(provided, style) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

function QuoteItem({
  task,
  isdragging,
  isGroupedOver,
  provided,
  style,
  isClone,
  subTaskIndex,
  title,
  overlay,
  active,
  onDragEndSteps,
  addStep,
  toggleSubTask,
  toggleStep,
  toggleOverlay,
  updateDescription,
  updateStep,
  updateNotes,
  deleteStep,
  deleteSubtask,
  deleteObjective,
}) {
  const isUrl = (str) => {
    // Regular expression to match URL pattern
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    return urlPattern.test(str);
  };

  const [stepIndex, setStepIndex] = useState();
  const [warning, setWarning] = useState(false);

  const textareaRef = useRef(null);

  // Function to auto resize the textarea
  const autoResizeTextarea = () => {
    if (textareaRef.current) {
      let scrollHeight = textareaRef.current.scrollHeight;
      let clientHeight = textareaRef.current.clientHeight;
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.minHeight = "12px";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  // UseEffect to call autoResizeTextarea when the task description changes
  useEffect(() => {
    autoResizeTextarea();
  }, [task.description]);

  const [calloutActive, setCalloutActive] = useState(false);

  const handleCalloutDisplay = () => {
    setCalloutActive((calloutActive) => !calloutActive);
  };

  return (
    <>
      <Container
        isdragging={isdragging}
        isGroupedOver={isGroupedOver}
        isClone={isClone}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getStyle(provided, style)}
        data-is-dragging={isdragging}
        data-testid={task.id}
        data-index={subTaskIndex}
        // aria-label={`${ta.author.name} quote ${quote.content}`}
      >
        {isClone ? <CloneBadge>Clone</CloneBadge> : null}
        <div
          className={
            task.completed === 0
              ? "objective__col--task"
              : "objective__col--task-active"
          }
        >
          <div
            className={
              task.completed === 0 ? "task__radio" : "task__radio--active"
            }
            onClick={() => toggleSubTask(subTaskIndex, title)}
          ></div>
          {isUrl(task.description) ? (
            <a href={task.description} target="_blank" rel="noreferrer">
              <textarea
                className={
                  task.completed === 0 ? "task--input" : "task--input completed"
                }
                value={task.description}
                onChange={(event) =>
                  updateDescription(event, title, subTaskIndex)
                }
                onClick={() => toggleSubTask(subTaskIndex, title)}
                readOnly
                ref={textareaRef}
              ></textarea>
            </a>
          ) : (
            <textarea
              className={
                task.completed === 0 ? "task--input" : "task--input completed"
              }
              value={task.description}
              onChange={(event) =>
                updateDescription(event, title, subTaskIndex)
              }
              ref={textareaRef}
            ></textarea>
          )}

          <FontAwesomeIcon
            className="objective__col--icon ellipsis"
            icon={faEllipsisVertical}
            onClick={() => {
              toggleOverlay(true);
              handleCalloutDisplay();
            }}
          />
        </div>
      </Container>
      <Callout
        id={task.id}
        active={active}
        steps={task.steps}
        overlay={overlay}
        calloutActive={calloutActive}
        stepsLength={task.steps.length}
        subTaskIndex={subTaskIndex}
        description={task.description}
        notes={task.notes}
        columnId={title}
        onDragEndSteps={onDragEndSteps}
        addStep={addStep}
        toggleStep={toggleStep}
        toggleOverlay={toggleOverlay}
        updateDescription={updateDescription}
        updateStep={updateStep}
        updateNotes={updateNotes}
        deleteStep={deleteStep}
        deleteSubtask={deleteSubtask}
        deleteObjective={deleteObjective}
        handleCalloutDisplay={handleCalloutDisplay}
      />
    </>
  );
}

export default React.memo(QuoteItem);
