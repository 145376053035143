// React
import React, { useState } from "react";

// Internal Components
import {
  ValuesIcon,
  DobIcon,
  MemoryIcon,
  VisionIcon,
  IkigaiIcon,
  QuoteIconV2,
  QuestionIcon,
  VitIcon,
  PreviousDay,
} from "../../components/Icons/Icon";

// External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp91 } from "@fortawesome/free-solid-svg-icons";
import YearTile from "./YearTile";

function YearMap({ year, handleChange }) {
  const [mapState, setMapState] = useState([
    {
      tile_name: "name",
      value: year.name && year.name,
      icon: <VitIcon />,
    },
    {
      tile_name: "birthday",
      value: year.birthdate && year.birthdate,
      icon: <DobIcon />,
    },
    {
      tile_name: "values",
      value: year.values && year.values,
      icon: <ValuesIcon />,
    },
    {
      tile_name: "vision",
      value: year.vision && year.vision,
      icon: <VisionIcon />,
    },
    {
      tile_name: "ikigai",
      value: year.ikigai && year.ikigai,
      icon: <IkigaiIcon />,
    },
    {
      tile_name: "continuation",
      value: year.continuation && year.continuation,
      icon: <PreviousDay />,
    },
    {
      tile_name: "quote",
      value: year.quote && year.quote,
      icon: <QuoteIconV2 />,
    },
    {
      tile_name: "memory",
      value: year.memory && year.memory,
      icon: <MemoryIcon />,
    },
  ]);
  return (
    <>
      <div id="yeartest">
        {mapState?.map((tile, index) => (
          <YearTile
            key={index}
            tile_name={tile.tile_name}
            value={tile.value}
            handleChange={handleChange}
          >
            {tile.icon}
          </YearTile>
        ))}
      </div>
    </>
  );
}

export default YearMap;
