import React from "react";

function SliderTooltip({ position, score }) {
  return (
    <span
      className="tooltip--span"
      style={{
        left: `${position}px`,
        zIndex: "-1",
        bottom: "22px",
      }}
    >
      {score}
    </span>
  );
}

export default SliderTooltip;
