// React
import React, { useState } from "react";
import { useSelector } from "react-redux";

// External Packages
import { faqQuestionData, faqAnswerData } from "./FAQ_DATA";

// External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

// Styling
import "./faq.css";

const FAQ = () => {
  // State
  const [select, setSelect] = useState(0);

  return (
    <section className="faq__section">
      <div className="faq__tile--question">
        <h4>Frequently Asked Questions</h4>
        {faqQuestionData &&
          faqQuestionData.map((q, i) => (
            <div key={i} className="question--row" onClick={() => setSelect(i)}>
              <p style={{ padding: "6px 0", fontWeight: "600" }}>
                {q.question}
              </p>
              <FontAwesomeIcon
                className="question--arrow"
                icon={faChevronRight}
              />
            </div>
          ))}
      </div>
      <div className="faq__tile--answer">
        <h4>Answer</h4>
        {faqAnswerData &&
          faqAnswerData.map((a, i) => (
            <p
              key={i}
              style={
                select === i
                  ? { display: "block", paddingTop: "12px", fontWeight: "400" }
                  : { display: "none" }
              }
            >
              {a.answer}
            </p>
          ))}
      </div>
    </section>
  );
};

export default FAQ;
